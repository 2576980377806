<template>
  <div class="d-flex flex-wrap py-5">
    <div class="flex-equal me-5">
      <table class="table table-flush fw-bold gy-1">
        <tbody>
          <tr>
            <td class="text-muted min-w-125px w-125px">N° Contrat</td>
            <td class="text-gray-800">{{ myItem.cnt_code }}</td>
          </tr>
          <tr>
            <td class="text-muted min-w-125px w-125px">Smart Account</td>
            <td class="text-gray-800">{{ myItem.cnt_libelle_smartaccount }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="flex-equal">
      <table class="table table-flush fw-bold gy-1">
        <tbody>
          <tr>
            <td class="text-muted min-w-125px w-125px">Date de début</td>
            <td class="text-gray-800">{{ myItem.cnt_date_debut }}</td>
          </tr>
          <tr>
            <td class="text-muted min-w-125px w-125px">Date de fin</td>
            <td class="text-gray-800">{{ myItem.cnt_date_fin }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";

export default defineComponent({
  name: "payment-methods",
  props: {
    cnt_seq: String,
  },
  components: {},
  setup(props) {
    onMounted(() => {
      MenuComponent.reinitialization();
    });
    const myItem = ref({
      cnt_seq: props.cnt_seq,
      cnt_code: "CNT-454654",
      cnt_date_debut: "08.09.2021",
      cnt_date_fin: "28.10.2021",
      cnt_libelle_smartaccount: "Snapsmart.com",
    });

    return {
      myItem,
    };
  },
});
</script>
