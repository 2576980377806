
import { defineComponent, onMounted, reactive } from "vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import headerFacture from "@/views/still/board/headerFacture.vue";
import headerOffre from "@/views/still/board/headerOffre.vue";
import headerContrat from "@/views/still/board/headerContrat.vue";
import mAxiosApi from "@/api";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "payment-methods",
  props: {
    cardClasses: String,
  },
  components: {
    headerFacture,
    headerOffre,
    headerContrat,
  },
  setup() {
    const router = useRouter();
    const state = reactive({
      list: [],
      loaderEnabled: true,
    });
    const capitalize = (val) => {
      return val.toUpperCase();
    };
    onMounted(async () => {
      MenuComponent.reinitialization();
      let rEch = await getAxios("/getEcheances");
      state.list = rEch.results;
      state.loaderEnabled = false;
    });
    const routeEcheance = (href, type) => {
      router.push({ name: type, params: { code: href } });
    };
    return {
      state,
      routeEcheance,
      capitalize,
    };
  },
});
export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
