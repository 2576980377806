<template>
  <div class="d-flex flex-wrap py-5" v-if="state.myItem.tof_seq">
    <div class="flex-equal me-5">
      <table class="table table-flush fw-bold gy-1">
        <tbody>
          <tr>
            <td class="text-muted min-w-125px w-125px">Offre</td>
            <td class="text-gray-800">{{ state.myItem.tof_code }}</td>
          </tr>
          <tr>
            <td class="text-muted min-w-125px w-125px">Date</td>
            <td class="text-gray-800">
              {{ formatDate(state.myItem.tof_date_creation) }}
            </td>
          </tr>
          <tr>
            <td class="text-muted min-w-125px w-125px">Echéance</td>
            <td class="text-gray-800">
              {{ formatDate(state.myItem.tof_duree_validite) }}
            </td>
          </tr>
          <tr>
            <td class="text-muted min-w-125px w-125px">Contact</td>
            <td class="text-gray-800">
              {{ state.myItem.con_prenom }}
              {{ state.myItem.con_nom }}
            </td>
          </tr>
          <tr>
            <td class="text-muted min-w-125px w-125px">Devise</td>
            <td class="text-gray-800">
              {{ state.myItem.dev_libelle }} ({{
                state.myItem.dev_sigle
              }})
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="flex-equal">
      <table class="table table-flush fw-bold gy-1">
        <tbody>
          <tr>
            <td class="text-muted min-w-125px w-125px">Total HT</td>
            <td class="text-gray-800">
              {{ formatMoney(state.myItem.tof_total_ht) }}
              {{ state.myItem.dev_sigle }}
            </td>
          </tr>
          <tr>
            <td class="text-muted min-w-125px w-125px">Total TTC</td>
            <td class="text-gray-800">
              {{ formatMoney(state.myItem.tof_total_ttc) }}
              {{ state.myItem.dev_sigle }}
            </td>
          </tr>
          <tr>
            <td class="text-muted min-w-125px w-125px">&nbsp;</td>
            <td class="text-gray-800">&nbsp;</td>
          </tr>
          <tr>
            <td class="text-muted min-w-125px w-125px">Livraison</td>
            <td class="text-gray-800">
              {{ state.myItem.tof_texte_livraison }}
            </td>
          </tr>
          <tr>
            <td class="text-muted min-w-125px w-125px">Délai de livraison</td>
            <td class="text-gray-800">
              {{ state.myItem.tof_texte_temps_de_livraison }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive } from "vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import * as moment from "moment";
import mAxiosApi from "@/api";

export default defineComponent({
  name: "payment-methods",
  props: {
    tof_seq: String,
  },
  components: {},
  setup(props) {
    const state = reactive({
      myItem: {},
    });
    onMounted(async () => {
      MenuComponent.reinitialization();
      let rTof = await getAxios("/getHeadOffre/" + props.tof_seq );
      state.myItem = rTof.results[0];
    });
    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };
    const formatMoney = (n) => {
      if (n === undefined) return "0.00";
      return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };
    return {
      state,
      formatDate,
      formatMoney,
    };
  },
});
export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
</script>
