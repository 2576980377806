
import { defineComponent, onMounted, ref } from "vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";

export default defineComponent({
  name: "payment-methods",
  props: {
    cnt_seq: String,
  },
  components: {},
  setup(props) {
    onMounted(() => {
      MenuComponent.reinitialization();
    });
    const myItem = ref({
      cnt_seq: props.cnt_seq,
      cnt_code: "CNT-454654",
      cnt_date_debut: "08.09.2021",
      cnt_date_fin: "28.10.2021",
      cnt_libelle_smartaccount: "Snapsmart.com",
    });

    return {
      myItem,
    };
  },
});
